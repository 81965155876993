import {
  SET_CACHE_PROJECT,
  SET_SCREEN,
  SET_PROJECT,
  SET_CURRENT,
  SET_VOICE,
  SET_TEMPLATE,
  SET_CLIENT_SETTINGS,
  SET_VALIDATION,
  SET_IS_CHANGE,
  SET_TEMPLATE_CHANGED,
  SET_FETCH_TEMPLATE,
  SET_PREVIEW,
  SET_RECOMMENDED_TEMPLATES,
} from './constants';
/* EDIT PROJECT reducer actions */
export const EditProjectActions = {
  setCacheProject: payload => {
    return dispatch => {
      dispatch({ type: SET_CACHE_PROJECT, payload });
    };
  },
  setScreen: payload => {
    return dispatch => {
      dispatch({ type: SET_SCREEN, payload });
    };
  },
  setProject: payload => {
    return dispatch => {
      dispatch({ type: SET_PROJECT, payload });
    };
  },
  setCurrent: payload => {
    return dispatch => {
      dispatch({ type: SET_CURRENT, payload });
    };
  },
  setVoice: payload => {
    return dispatch => {
      dispatch({ type: SET_VOICE, payload });
    };
  },
  setTemplate: payload => {
    return dispatch => {
      dispatch({ type: SET_TEMPLATE, payload });
    };
  },
  setClientSettings: payload => {
    return dispatch => {
      dispatch({ type: SET_CLIENT_SETTINGS, payload });
    };
  },
  setIsChanges: payload => {
    return dispatch => {
      dispatch({ type: SET_IS_CHANGE, payload });
    };
  },
  setValidation: payload => {
    return dispatch => {
      dispatch({ type: SET_VALIDATION, payload });
    };
  },
  setTemplateChanged: payload => {
    return dispatch => {
      dispatch({ type: SET_TEMPLATE_CHANGED, payload });
    };
  },
  setFetchTemplate: payload => {
    return dispatch => {
      dispatch({ type: SET_FETCH_TEMPLATE, payload });
    };
  },
  setPreview: payload => {
    return dispatch => {
      dispatch({ type: SET_PREVIEW, payload });
    };
  },
  setRecommendedTemplates: payload => {
    return dispatch => {
      dispatch({ type: SET_RECOMMENDED_TEMPLATES, payload });
    };
  },
};
