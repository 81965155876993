import axios from 'axios';
import { Axioslib, AxioslibUser } from '../lib/axioslib';
import { dispatchStore, getUserItem, showContactSalesModalOnStorageExceeded } from './helper';
import { updateSequenceIds } from '../lib';

export const getProjectID = body => {
  let Id = '';
  if (body.user_id) {
    Id = body.user_id;
  } else {
    let userId = getUserItem('id');
    let orgId = getUserItem('orgId');
    let AdminRole = getUserItem('role');
    Id = AdminRole == 0 ? userId : orgId;
  }
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      AxioslibUser.post('api/get_projectsId', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== '') {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode,
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: ' No response from the server',
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({
              status: 101,
              message: 'Something went wrong. Please try again',
            });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'No response from the server' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const uploadMediaFile = (body, config) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('/media/asset/uploadMedia', body, {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data.data) {
              if (response.data.data && response.data.data.mediaurl) {
                let result = {
                  data: response.data.data.mediaurl,
                  message: response.data.message,
                  status: response.data.status,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        const errorMsg = axios.isCancel(error) ? 'Upload cancelled' : error;

        if (error.response.status !== 500) {
          showContactSalesModalOnStorageExceeded(error);
        }
        reject(errorMsg);
      });
  });
};

export const uploadAdminMediaFile = (body, config) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('/media/admin/asset/uploadMedia', body, {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.data !== undefined && response.data.data !== null) {
              if (
                response.data.data !== undefined &&
                response.data.data !== null &&
                response.data.data !== '' &&
                response.data.data.mediaurl !== undefined &&
                response.data.data.mediaurl !== null
              ) {
                let result = {
                  data: response.data.data.mediaurl,
                  message: response.data.message,
                  status: response.data.status,
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: ' No response from the server',
                });
              }
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        const errorMsg = axios.isCancel(error) ? 'Upload cancelled' : 'No server Response';
        resolve({ status: 101, message: errorMsg });
      });
  });
};

export const getAllFonts = () => {
  return getFonts();
};

export const SaveClusterDetails = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/save-cluster`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              let result = {
                data: response.data.Body,
                message: response.data.Body.message,
                status: response.data.StatusCode,
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: response.data.status,
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(e => {
        console.log(e);
        resolve({ status: 101, message: 'No response from the server' });
      });
  });
};

export const emailNotification = body => {
  let userId = getUserItem('id');
  let orgId = getUserItem('orgId');
  let AdminRole = getUserItem('role');
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== '') {
      body['user_id'] = Id;
      AxioslibUser.post('/api/project-rendered', body, {
        headers: {
          user_id: Id,
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.data.status !== undefined && response.data.status !== null && response.data.status !== '') {
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        })
        .catch(error => {
          resolve({ status: 101, message: 'Something went wrong' });
        });
    } else {
      resolve({ status: 101, message: 'Invalid Parameters' });
    }
  });
};

export const createClient = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`/api/client/v1/create`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        resolve(response);
      })
      .catch(e => {
        resolve({ code: e.response.data.code, message: e.response.data.error });
      });
  });
};

export const checkDomain = subdomain => {
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`/api/client/v1/check-domain`, {
      headers: {
        subdomain: subdomain,
      },
    })
      .then(response => {
        resolve(response);
      })
      .catch(e => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const uploadFile = formData => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  formData.append('client', process.env.REACT_APP_UPLOAD_SERVICE_CLIENT_NAME);
  formData.append('bucket', process.env.REACT_APP_UPLOAD_SERVICE_BUCKET_NAME);

  const url = process.env.REACT_APP_UPLOAD_SERVICE_URL;

  return new Promise((resolve, reject) => {
    AxioslibUser.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${accessToken}`,
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        const errorMsg = axios.isCancel(error) ? 'Upload cancelled' : 'No server Response';
        resolve({ status: 101, message: errorMsg });
      });
  });
};

export const getClientSetting = () => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`/api/client-setting/v1/list`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.data))
      .catch(error => reject(error.message || error));
  });
};

export const updateClientSettingVoice = payload => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.patch(`/api/client-setting/v1/update/client-setting-voice`, payload, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.data))
      .catch(error => reject(error.message || error));
  });
};

export const getProject = projectId => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/project/v1/${projectId}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.data))
      .catch(error => reject(error.message || error));
  });
};

export const getFonts = () => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get('/api/generic/v1/fonts', {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject({ status: 101, message: error.message || error }));
  });
};

export const getProjectStyles = projectId => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.get(`/api/style/v1/project/${projectId}/get`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const saveProjectStyles = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post('/api/style/v1/create', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const saveProjectImage = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    Axioslib.post('/api/project/v1/update/image', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const getTemplateData = (body, projectId) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  let filter = {
    screenContent: body,
    projectId: projectId,
  };
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/template/v1/suggest-templates', filter, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const updateProject = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  updateSequenceIds(body?.dbdata);

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/project/v1/update', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const generateProjectPreview = (body, forceGenerate = false) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  if (forceGenerate) {
    body.forceGenerate = true;
  }
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/project/v1/generate-preview', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const getAssetsV1 = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/generic/v1/search-repo', body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data?.Body?.result) {
            let result = {
              data: response.data.Body.result,
              message: response.data.Body.message,
              status: response.data.StatusCode,
              total: response.data.Body.total,
            };
            resolve(result);
          } else {
            reject('No response from the server');
          }
        } else {
          reject('Something went wrong. Please try again');
        }
      })
      .catch(error => {
        console.log({ getAssetsV1Error: error });
        const errMsg = error?.response?.data?.message || 'Something went wrong';
        reject(errMsg);
      });
  });
};

export const publicShutterStockSearchV1 = body => {
  let userId = getUserItem('clientId');
  let filter = {
    user_id: userId,
    imgId: body,
  };
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/save-shutterstock', filter, {
      headers: {
        user_id: userId,
        authorization: `Bearer ${accessToken}`,
        // "token":"QCSU7nvg5Xk5qUdqQgXFdQ"
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data) {
            resolve(response.data);
          } else {
            reject('No response from the server');
          }
        } else {
          reject('Something went wrong. Please try again');
        }
      })
      .catch(error => {
        console.log({ publicShutterStockSearchV1Error: error });

        reject(error.response);
      });
  });
};

export const publicSearchV1 = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/generic/v1/image/search-public', body, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data?.result) {
            let result = {
              data: response.data.result.Images,
            };
            if (response.data.result.total_count) {
              result.total_count = response.data.result.total_count;
            }
            resolve(result);
          } else {
            reject('No response from the server');
          }
        } else {
          reject('Something went wrong. Please try again');
        }
      })
      .catch(error => {
        console.log({ publicSearchV1Error: error });

        const errMsg = error?.response?.data?.message || 'Something went wrong';
        reject(errMsg);
      });
  });
};

export const videoBlockSearchV1 = body => {
  let userId = getUserItem('id');
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/generic/v1/search-videoblock', body, {
      headers: {
        user_id: userId,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data.results) {
              let result = {
                data: response.data.results,
                totalcount: response.data.total_results,
                message: response.data.message,
                status: response.status,
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        let response = error.response;
        if (response.status === 400) {
          if (response.data) {
            if (response.data.results) {
              let result = {
                message: response.data.message,
                status: 101,
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: ' No response from the server',
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No records available ' });
        }
      });
  });
};

export const downloadVideoBlockUrlV1 = config => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/generic/v1/download-videoblock', config, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (response.data) {
          if (response.data) {
            if (response.data.data) {
              const result = {
                data: response.data.data,
                message: response.data.message,
                status: response.data.status,
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: ' No response from the server' });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({
            status: 101,
            message: 'Something went wrong. Please try again',
          });
        }
      })
      .catch(error => {
        let response = error.response;
        if (response.status === 400) {
          if (response.data) {
            if (response.data.results) {
              let result = {
                message: response.data.message,
                status: 101,
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: ' No response from the server',
              });
            }
          } else {
            resolve({ status: 101, message: ' No response from the server' });
          }
        } else {
          resolve({ status: 101, message: 'No records available ' });
        }
      });
  });
};

export const checkScript = script => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      'api/project/v1/check-script',
      { script },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const checkMaxParallelRender = () => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get('api/render/v1/check-max-parallel-render', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const getClients = query => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/client/v1/list-with-user?${query}`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const getTemplates = query => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/admin/template/v1/list?${query}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const getClientTemplateRelations = tid => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/admin/template/v1/${tid}/client-template/list`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const changeTemplateStatus = ({ tid, payload }) => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.put(`api/admin/template/v1/${tid}/status`, payload, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const deleteTemplate = ({ tid, client }) => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.delete(`api/admin/template/v1/${tid}/client/${client}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const exportClients = body => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/client/v1/export`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const assignTemplate = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/admin/template/v1/assign-template`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const renderTemplateData = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/admin/template/v1/render`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.Body))
      .catch(error => reject(error));
  });
};

export const renderedTemplateStatus = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/admin/template/v1/rendered-template-status`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data.Body.result))
      .catch(error => reject(error));
  });
};

export const getClientSettingOfClient = client => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/admin/client-setting/v1/${client}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const saveClientSettingOfClient = ({ data, client }) => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/admin/client-setting/v1/save-client-setting/${client}`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const assignPlan = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/plan/admin/v1/assign`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const assignTemplatesToPlan = data => {
  const accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/plan/admin/v1/manage-templates`, data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const getAssetStorageLimit = () => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.get('api/generic/v1/asset-size-limit', {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const createClientBySuperadmin = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`/api/client/admin/v1/create`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        resolve(response);
      })
      .catch(e => {
        resolve({ code: e.response.data.code, message: e.response.data.error });
      });
  });
};

export const getSuperAdminLogs = body => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`/api/admin/logs/v1/list`, body, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const getSuperAdminUsers = search => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`/api/admin/users/v1/list?search=${search}`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const contactSales = data => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('/api/generic/v1/contact-sales', data, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const textToSpeech = ({ audioScript, rate, voiceId, style }) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      'api/generic/v1/text-to-speech',
      { audioScript, rate, voiceId, style },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const checkClusterReady = renderClusterName => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      'api/generic/v1/check-renderCluster-status',
      { renderClusterName },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const trimMP4File = payload => {
  /*
  start_time: trim[0],
        end_time: trim[1],
        formData,
        tag_names: selectedTags,
        isPrivate,
  */
  let accessToken = JSON.parse(localStorage.getItem('_user'));
  return new Promise((resolve, reject) => {
    AxioslibUser.post('media/trim-video', payload.formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${accessToken}`,
        private: payload.isPrivate,
        tag_names: payload.tag_names,
        start_time: payload.start_time,
        end_time: payload.end_time,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => {
        const errorMsg = axios.isCancel(error) ? 'Upload cancelled' : error;

        showContactSalesModalOnStorageExceeded(error);

        reject(errorMsg);
      });
  });
};

export const generateAndSaveVoices = ({ projectId, renderId, moduleId, slideId }) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      'api/project/v1/generate-and-store-audio-script-voices',
      { projectId, renderId, moduleId, slideId },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const applyExternalAudio = ({ renderId }) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      'api/project/v1/apply-external-audio',
      { renderId },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const reportError = payload => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/error-log/v1/report-error', payload, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.message || error));
  });
};

export const createErrorLog = (payload, canReport = false) => {
  let accessToken = JSON.parse(localStorage.getItem('_user'));

  return new Promise((resolve, reject) => {
    AxioslibUser.post('api/error-log/v1/create', payload, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        const errorLog = { error: response?.data?.message, errorId: response?.data?.errorId };

        canReport && dispatchStore.showErrorLogReportModal(errorLog);

        resolve(response.data);
      })
      .catch(error => reject(error.message || error));
  });
};
