import natural from "natural";

export const calculatePatternMatchPercentage = (str1, str2) => {
  const distance = natural.LevenshteinDistance(str1, str2);
  const maxLength = Math.max(str1.length, str2.length);
  const matchPercentage = (1 - distance / maxLength) * 100;
  return Math.round(matchPercentage * 100) / 100; // Round to 2 decimal places
};

export const OSTTextValidation = (text) => {
  const regexMatch = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};:\\|,.0-9<>\/?'"`]*$/.test(text);
  return regexMatch;
}

