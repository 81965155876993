import Swal from 'sweetalert2';

import { store } from '../store/store';
import { calculatePatternMatchPercentage } from './string-compare';
import { getRandomNumber, showToaster } from '../services/helper';
import { BULLETFONT, FONT_URL, HEADERFONT, PARAGRAPHFONT } from '../constants';
import { EditProjectActions } from '../store/Reducers/editProjectReducer/actions';
import { generateProjectPreview, getTemplateData, updateProject } from '../services/api.service';

/*
Find only First Screen only
*/
export const getFirstScreen = data => {
  try {
    let projectData = { ...data };
    let projectId = Object.keys(projectData)[0];
    if (projectId) {
      let firstModule = Object.keys(projectData[projectId].Modules)[0];
      let firstSlide = Object.keys(projectData[projectId].Modules[firstModule].slides)[0];
      let firstScreen = Object.keys(projectData[projectId].Modules[firstModule].slides[firstSlide].Screens)[0];
      return {
        moduleName: firstModule ? firstModule : '',
        slideName: firstSlide ? firstSlide : '',
        screenName: firstScreen ? firstScreen : '',
        firstScreen: projectData[projectId].Modules[firstModule].slides[firstSlide].Screens[firstScreen],
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/*
Find all screen according to the currenct data.
*/
export const getAllScreen = (project, active) => {
  try {
    if (Object.keys(project).length && Object.keys(active).length) {
      return project.ProjectData[project.ProjectID].Modules[active.moduleName].slides[active.slideName].Screens;
      //  return project.ProjectData[id].Modules[active.moduleName].slides[active.slideName].Screens;
    }
    return false;
  } catch (error) {
    console.error(error);
  }
};
export const getSlideAudio = (project, active) => {
  try {
    if (Object.keys(project).length && Object.keys(active).length) {
      return project.ProjectData[project.ProjectID]?.Modules[active.moduleName]?.slides[active?.slideName]?.SlideAudioVO;
    }
    return false;
  } catch (error) {
    console.error(error);
  }
};
export const updateSlideAudio = (project, active, mp3) => {
  try {
    if (Object.keys(project).length && Object.keys(active).length) {
      project.ProjectData[project.ProjectID].Modules[active.moduleName].slides[active.slideName].SlideAudioVO = mp3;
      return project;
      //  return project.ProjectData[id].Modules[active.moduleName].slides[active.slideName].Screens;
    }
    return false;
  } catch (error) {
    console.error(error);
  }
};

/*
Find screen according to the currenct data.
*/
export const getScreen = (project, active) => {
  try {
    if (Object.keys(project).length && Object.keys(active).length) {
      let id = Object.keys(project)[0];
      return project[id].Modules[active.moduleName].slides[active.slideName].Screens[active.screenName];
      // return project[id].Modules[active.ActiveModule].slides[active.ActiveSlide].Screens[active.ActiveScreen];
    }
    return false;
  } catch (error) {
    console.error(error);
  }
};
/*
Find all screens according to the currenct data
*/
export const getScreensNames = ({ project, current }) => {
  try {
    let screens = project.ProjectData[project.ProjectID].Modules[current?.moduleName].slides[current?.slideName]?.Screens;

    if (screens) {
      return Object.entries(screens).map(item => {
        const { ScreenName, mediaurl } = item[1];
        return { id: item[0], ScreenName, mediaurl };
      });
    }
  } catch (error) {
    console.error(error);
  }
};

/* 
  Get Screen Data according to the Contenttype Value and optionally with clue property 
  
  Arugument :
    screen : Object
    name:String,
    clue : String

  Return : Array[Object]
*/
export const getDataFromScreen = ({ screen, name, clue = false }) => {
  try {
    let collection = [];
    if ((screen, name)) {
      Object.entries(screen).filter(item => {
        let obj = {};
        if (!clue && item[1].Contenttype === name) {
          obj = { id: item[0], ...item[1] };
          collection.push(obj);
        } else if (clue && item[1].Contenttype === name) {
          /* If we have a clue we need to match with contentdescript wit last 3 charachers. Ex: [H] */
          if (item[1].Contentparams.contentdescription.slice(-3) === clue) {
            obj = { id: item[0], ...item[1] };
            collection.push(obj);
          }
        }
      });
    }
    if (collection.length === 0) {
      return [];
      // return [[]];
    }
    return collection;
  } catch (error) {
    console.error();
  }
};

/*
Arugment : screens,name,current
screens : Object
name : String
current: Object

Create new OS object according to the currenct data and returen OS Object for screen to add
*/
export const newOS = (screens, name, current) => {
  try {
    let reg = /^OS/;

    /* Finding Maximum Numer of OS */
    const allNum = Object.keys(screens).map(item => (reg.test(item) ? parseInt(item.slice(-2)) : 0));
    const maxNum = Math.max(...allNum);

    /* Finding Maximum Sequence Id */
    const sequencIds = Object.values(screens).map(item => (item?.Contentparams?.sequenceid ? parseInt(item?.Contentparams?.sequenceid) : 0));
    const maxSequenceId = parseInt(Math.max(...sequencIds) + 1);
    let displayName = '';
    switch (name) {
      case 'B':
        displayName = 'Bullet';
        break;
      case 'P':
        displayName = 'Paragraph';
        break;
      case 'H':
        displayName = 'Heading';
        break;
      default:
        displayName = 'Paragraph';
    }

    /*  Sample data */

    let newObj = {};
    newObj['OS0' + parseInt(maxNum + 1)] = {
      Contenttype: 'OST',
      Contentparams: {
        FontSizeH: current?.FontSizeH,
        FontSizeP: current?.FontSizeP,
        FontColour: current?.FontColour,
        FontFamily: current?.FontFamily,
        contenttag: 'Null',
        sequenceid: maxSequenceId.toString(),
        contentdescription: `[${name}]`,
        // contentdescription: 'Sample  ' + displayName + parseInt(maxNum + 1) + ' [' + name + ']',
      },
    };

    return newObj;
  } catch (err) {
    console.error(err);
  }
};

/* 
It returns total amount of Paragrph, Bullets and Heading information 

*/
export const getContentInfo = screen => {
  try {
    let totalP = getDataFromScreen({ screen, name: 'OST', clue: '[P]' }).length;
    let totalH = getDataFromScreen({ screen, name: 'OST', clue: '[H]' }).length;
    let totalB = getDataFromScreen({ screen, name: 'OST', clue: '[B]' }).length;

    return { totalP, totalH, totalB };
  } catch (error) {
    console.error(error);
  }
};

export const removeAllParagraph = screen => {
  try {
    let cloneScreen = JSON.parse(JSON.stringify(screen));
    let totalP = getDataFromScreen({ screen: cloneScreen, name: 'OST', clue: '[P]' });
    let i = 0;
    while (i < totalP.length) {
      delete cloneScreen[totalP[i].id];
      i++;
    }

    return cloneScreen;
  } catch (error) {
    console.error(error);
  }
};

export const removeAllBullets = screen => {
  try {
    let cloneScreen = JSON.parse(JSON.stringify(screen));
    let totalP = getDataFromScreen({ screen: cloneScreen, name: 'OST', clue: '[B]' });
    let i = 0;
    while (i < totalP.length) {
      delete cloneScreen[totalP[i].id];
      i++;
    }

    return cloneScreen;
  } catch (error) {
    console.error(error);
  }
};

export const currentScreenData = (cacheProject, current) => {
  try {
    const moduleId = current.moduleName;
    const slideId = current.slideName;
    const screenId = current.screenName;
    let projectInputs = cacheProject.ProjectData;

    const screenContent = {
      [cacheProject.ProjectID]: {
        ProjectName: cacheProject.ProjectName,
        Modules: {
          [moduleId]: {
            ModuleName: projectInputs[cacheProject.ProjectID]?.['Modules']?.[moduleId]?.['ModuleName'],
            slides: {
              [slideId]: {
                SlideName: projectInputs[cacheProject.ProjectID]?.['Modules']?.[moduleId]?.['slides'][slideId]?.['SlideName'],
                SlideAudioVO: 'Null',
                Screens: {
                  [screenId]: projectInputs[cacheProject.ProjectID]?.Modules[moduleId]?.slides[slideId]?.Screens[screenId],
                },
              },
            },
          },
        },
      },
    };
    return screenContent;
  } catch (error) {
    console.error(error);
  }
};

export const generateNewSegment = (project, current, voiceSetting = {}) => {
  try {
    let cloneProject = JSON.parse(JSON.stringify(project));
    const { FontColour, FontFamily, FontSizeP, FontSizeH, bgColor } = { ...current };
    let allScreens = getAllScreen(cloneProject, current);
    let obj = {};

    const slideNo = current.slideName.replace('SL', '');
    const allScreensArr = Object.keys(allScreens);
    const lastScreen = allScreensArr[allScreensArr.length - 1];

    const screenNumber = lastScreen.replace(`SC${slideNo}.0`, '');
    const screenNumberSplit = screenNumber.split(`SC${slideNo}.`)[1];

    let data;
    if (screenNumberSplit) data = parseInt(screenNumberSplit) + 1;
    else data = parseInt(screenNumber) + 1;

    const newScreenId = data < 10 ? `SC${slideNo}.0${data}` : `SC${slideNo}.${data}`;
    const screenName = data < 10 ? '0' + data : data;

    let cloneScreen = {
      ScreenName: 'Segment ' + screenName,
      mediaurl: {
        PreviewUrl: '',
        ThumbnailUrl: '',
      },
    };

    cloneScreen['BG01'] = {
      Contenttype: 'Background',
      Contentparams: {
        sequenceid: '1',
        contentdescription: '#2066a2',
        contenttag: 'Null',
      },
    };

    cloneScreen['AS01'] = {
      Contentparams: {
        contentdescription: '',
        audioFile: '',
        voiceStyle: voiceSetting?.voiceStyle ?? 'Narration',
        voiceId: voiceSetting?.voiceId ?? 'en-UK-theo',
        voiceRate: voiceSetting?.voiceRate ?? 0,
      },
      Contenttype: 'AudioScript',
    };
    cloneScreen['OS01'] = {
      Contentparams: {
        FontColour,
        FontFamily,
        FontSizeH,
        FontSizeP,
        contentdescription: '[P]',
        contenttag: 'Null',
      },
      Contenttype: 'OST',
    };
    cloneScreen['CS01'] = {
      Contenttype: 'ColourScheme',
      Contentparams: {
        sequenceid: '3',
        contentdescription: bgColor,
        contenttag: 'Null',
      },
    };
    cloneScreen['CS02'] = {
      Contenttype: 'ColourScheme',
      Contentparams: {
        sequenceid: '9',
        contentdescription: bgColor,
        contenttag: 'Null',
      },
    };
    cloneScreen['TM01'] = {
      Contenttype: 'TemplateID',
      Contentparams: {
        contenttag: 'Null',
        sequenceid: '8',
        TemplateType: 'motion_graphics',
        contentdescription: 'no template',
        sourcescreen: '',
      },
    };
    cloneScreen['SD01'] = {
      Contenttype: 'ScreenDuration',
      Contentparams: {
        contenttag: 'Null',
        sequenceid: '6',
        contentdescription: '7',
      },
    };
    cloneScreen['VD01'] = {
      Contenttype: 'Video',
      Contentparams: {
        contenttag: '',
        sequenceid: '11',
        contentdescription: '',
        searchstring: '',
      },
    };
    // cloneScreen['IM01'] = {
    //   Contenttype: 'Image',
    //   Contentparams: {
    //     sequenceid: '21',
    //     contentdescription: '',
    //     searchstring: '',
    //     contenttag: '',
    //   },
    // };
    // cloneScreen['IL01'] = {
    //   Contenttype: 'Illustration',
    //   Contentparams: {
    //     sequenceid: '5',
    //     contentdescription: '',
    //     contenttag: '',
    //     searchstring: '',
    //   },
    // };
    cloneScreen['SD01'] = {
      Contenttype: 'ScreenDuration',
      Contentparams: {
        contenttag: 'Null',
        sequenceid: '10',
        contentdescription: '7',
      },
    };
    obj[newScreenId] = cloneScreen;
    return obj;
  } catch (error) {
    console.error(error);
  }
};
/* According to the current value newscreen will be added on project  */
export const addScreen = (project, current, newscreen) => {
  try {
    let cloneProject = { ...project };
    let oldScreens = cloneProject.ProjectData[project.ProjectID].Modules[current.moduleName].slides[current.slideName].Screens;
    cloneProject.ProjectData[project.ProjectID].Modules[current.moduleName].slides[current.slideName].Screens = { ...oldScreens, ...newscreen };
    return cloneProject;
  } catch (error) {
    console.error(error);
  }
};
/* Update Voice Setting to all segments */
export const applyVoiceToAllSegment = ({ project, current, voice }) => {
  try {
    /*
    applyFontToAllSegment
    */
    let allScreens = getAllScreen(project, current);

    Object.entries(allScreens).map(([screenKey, screenValue]) => {
      Object.entries(screenValue).map(([screenValueKey, screenValueValue]) => {
        if (screenValueValue.Contenttype === 'AudioScript') {
          screenValueValue.Contentparams.voiceId = voice.selectedVoice.voiceId;
          screenValueValue.Contentparams.voiceRate = voice.voiceRate;
          screenValueValue.Contentparams.voiceStyle = voice.emotion;
        }
      });
    });
    return allScreens;
  } catch (error) {
    console.error(error);
  }
};

/* Update Font Setting to all segments */
export const applyFontToAllSegment = ({ project, current }) => {
  try {
    /*
    applyFontToAllSegment
    */
    let allScreens = getAllScreen(project, current);

    Object.entries(allScreens).map(([screenKey, screenValue]) => {
      Object.entries(screenValue).map(([screenValueKey, screenValueValue]) => {
        if (screenValueValue.Contenttype === 'OST') {
          screenValueValue.Contentparams.FontFamily = current.FontFamily;
          screenValueValue.Contentparams.FontColour = current.FontColour;
          screenValueValue.Contentparams.FontSizeP = current.FontSizeP;
        }

        if (screenValueKey === 'CS01') {
          screenValueValue.Contentparams.contentdescription = current.bgColor;
        }
      });
    });
    return allScreens;
  } catch (error) {
    console.error(error);
  }
};
export const updateSettings = (projectScreens, fontFamily, fontStyle, textColor, bgColor) => {
  try {
    Object.entries(projectScreens).map(([screenKey, screenValue]) => {
      Object.entries(screenValue).map(([screenValueKey, screenValueValue]) => {
        if (screenValueValue.Contenttype === 'OST') {
          screenValueValue.Contentparams.FontFamily = FONT_URL + fontFamily;

          if (fontStyle) {
            screenValueValue.Contentparams.FontFamily = screenValueValue.Contentparams.FontFamily + '/' + fontStyle;
          }

          screenValueValue.Contentparams.FontColour = textColor;
        }

        if (screenValueKey === 'CS01') {
          screenValueValue.Contentparams.contentdescription = bgColor;
        }
      });
    });
    return projectScreens;
  } catch (error) {
    console.error(error);
  }
};
export const updateScreenOnProject = ({ project, current, screen }) => {
  try {
    let cloneProject = { ...project };
    cloneProject.ProjectData[cloneProject.ProjectID].Modules[current.moduleName].slides[current.slideName].Screens[current.screenName] = screen;

    return cloneProject;
  } catch (error) {
    console.error(error);
  }
};
export const updateScreensOnProject = async ({ editProject, copyCacheProject }) => {
  try {
    let updatedProject = JSON.parse(JSON.stringify(editProject?.project));

    let validationErrorArray = [];
    const projectModules = updatedProject.ProjectData[updatedProject.ProjectID].Modules;
    const projectDataArray = Object.entries(projectModules);

    let slideChanges = [];
    let screenChanges = [];
    let hasDataChanged = false;
    let useDifferentTemplate = false;

    projectDataArray.map(([moduleKey2, moduleValue2]) => {
      const slideArray2 = Object.entries(moduleValue2.slides);
      let cachedProjectModule = editProject.cacheProject.ProjectData[updatedProject.ProjectID]?.Modules?.[moduleKey2];
      if (copyCacheProject) {
        cachedProjectModule = copyCacheProject.ProjectData[updatedProject.ProjectID]?.Modules?.[moduleKey2];
      }
      slideArray2.map(([slideKey2, slideValue2]) => {
        const screenArray2 = Object.entries(slideValue2.Screens);

        screenArray2.map(([screenKey2, screenValue2]) => {
          const screenValue2Array = Object.entries(screenValue2);

          const arrayData = [];
          let ostCountValidateArr = [];
          let imageCountValidateLocal = [];
          let videoCountValidateLocal = [];
          let illustrationCountValidateLocal = [];
          let needRenderReset = false;

          screenValue2Array.sort((a, b) => {
            const isTa = /^TM/.test(a[0]);
            const isTb = /^TM/.test(b[0]);

            if (isTa && !isTb) return 1;
            if (isTb && !isTa) return -1;
            return 0;
          });

          let OSTFound = false;

          let items = {
            ost: 0,
            ostH: 0,
            ostP: 0,
            ostB: 0,
          };
          let ostHArray = [];
          let ostBArray = [];
          let ostPArray = [];
          let ostString = '';
          let audioScriptString = '';
          let ostCountValidate = [];
          let imageCountValidate = [];
          let videoCountValidate = [];
          let illustrationCountValidate = [];

          let oldScreenData;

          if (cachedProjectModule?.slides?.[slideKey2]) {
            oldScreenData = cachedProjectModule.slides[slideKey2].Screens[screenKey2];
          }

          if (cachedProjectModule?.slides?.[slideKey2]?.Screens?.[screenKey2]) {
            if (cachedProjectModule.ModuleName !== moduleValue2.ModuleName) {
              hasDataChanged = true;
            }

            if (cachedProjectModule.slides[slideKey2].SlideName !== moduleValue2.slides[slideKey2].SlideName) {
              hasDataChanged = true;
            }

            if (
              cachedProjectModule.slides[slideKey2].Screens[screenKey2].ScreenName !== moduleValue2.slides[slideKey2].Screens[screenKey2].ScreenName
            ) {
              console.log(cachedProjectModule.slides[slideKey2].Screens[screenKey2].ScreenName, 'cachevalue');
              console.log(moduleValue2.slides[slideKey2].Screens[screenKey2].ScreenName, 'realvalue');

              console.log('check');

              hasDataChanged = true;
            }
          } else {
            arrayData.push(1);
            hasDataChanged = true;
            useDifferentTemplate = true;
          }

          if (!oldScreenData) {
            arrayData.push(1);
            hasDataChanged = true;
          }

          screenValue2Array.map(([screenValue2Key, screenValue2Value]) => {
            if (screenValue2Value.Contenttype === 'OST') {
              OSTFound = true;

              if (!screenValue2Value.Contentparams.contentdescription) {
                let ostData = '';
                if (screenValue2Value['Contentparams']['contentdescription'].endsWith(HEADERFONT)) {
                  ostData = screenValue2Key.replace('OS', 'Header ');
                } else if (screenValue2Value['Contentparams']['contentdescription'].endsWith(PARAGRAPHFONT)) {
                  ostData = screenValue2Key.replace('OS', 'Paragraph ');
                }
                if (screenValue2Value['Contentparams']['contentdescription'].endsWith(BULLETFONT)) {
                  ostData = screenValue2Key.replace('OS', 'List ');
                }

                validationErrorArray.push(
                  `<div>${moduleValue2.ModuleName + '-' + slideValue2.SlideName + '-' + screenValue2.ScreenName + '-' + ostData}</div>`
                );
              } else {
                items.ost++;

                if (screenValue2Value['Contentparams']['contentdescription'].endsWith(HEADERFONT)) {
                  items.ostH++;

                  ostHArray.push(screenValue2Value.Contentparams.contentdescription.length);
                  ostString += screenValue2Value.Contentparams.contentdescription;
                } else if (screenValue2Value['Contentparams']['contentdescription'].endsWith(PARAGRAPHFONT)) {
                  items.ostP++;

                  ostPArray.push(screenValue2Value.Contentparams.contentdescription.length);
                  ostString += ' ' + screenValue2Value.Contentparams.contentdescription;
                } else if (screenValue2Value['Contentparams']['contentdescription'].endsWith(BULLETFONT)) {
                  items.ostB++;

                  ostBArray.push(screenValue2Value?.Contentparams?.contentdescription?.length);
                  ostString += ' ' + screenValue2Value.Contentparams.contentdescription;
                }
              }

              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (screenValue2Value['Contentparams']['contentdescription'] !== oldScreenData[screenValue2Key].Contentparams.contentdescription) {
                    if (
                      screenValue2Value['Contentparams']['contentdescription'].length !==
                      oldScreenData[screenValue2Key].Contentparams.contentdescription.length
                    ) {
                      useDifferentTemplate = true;
                    }
                    arrayData.push(1);

                    hasDataChanged = true;

                    let data = [];
                    data.push(moduleKey2 + '_' + slideKey2 + '_' + screenKey2 + '_' + screenValue2Key);
                    ostCountValidateArr = [...ostCountValidate, ...data];
                  } else {
                    ostCountValidateArr = ostCountValidate;
                  }
                  if (screenValue2Value['Contentparams']['FontColour'] !== oldScreenData[screenValue2Key].Contentparams.FontColour) {
                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                  if (screenValue2Value['Contentparams']['FontFamily'] !== oldScreenData[screenValue2Key].Contentparams.FontFamily) {
                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                  if (screenValue2Value['Contentparams']['FontSizeH'] !== oldScreenData[screenValue2Key].Contentparams.FontSizeH) {
                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                  if (screenValue2Value['Contentparams']['FontSizeP'] !== oldScreenData[screenValue2Key].Contentparams.FontSizeP) {
                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                }
              }

              if (screenValue2Value['Contentparams']['FontColour'].length === 8) {
                screenValue2Value['Contentparams']['FontColour'] = screenValue2Value['Contentparams']['FontColour'].split('*')[0];
              }
              if (screenValue2Value['Contentparams']['contentdescription'].includes('[H]')) {
                return screenValue2Value['Contentparams']['contentdescription'].split('[H]')[0];
              }

              if (screenValue2Value['Contentparams']['contentdescription'].includes('[P]')) {
                return screenValue2Value['Contentparams']['contentdescription'].split('[P]')[0];
              }
              if (screenValue2Value['Contentparams']['contentdescription'].includes('[B]')) {
                return screenValue2Value['Contentparams']['contentdescription'].split('[B]')[0];
              }

              if (screenValue2Value['Contentparams']['contentdescription'].endsWith(HEADERFONT)) {
                screenValue2Value['Contentparams']['contentdescription'] = screenValue2Value['Contentparams']['contentdescription'] + '[H]';
              } else if (screenValue2Value['Contentparams']['contentdescription'].endsWith(PARAGRAPHFONT)) {
                screenValue2Value['Contentparams']['contentdescription'] = screenValue2Value['Contentparams']['contentdescription'] + '[P]';
              } else if (screenValue2Value['Contentparams']['contentdescription'].endsWith(BULLETFONT)) {
                screenValue2Value['Contentparams']['contentdescription'] = screenValue2Value['Contentparams']['contentdescription'] + '[B]';
              }
              screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();

              if (screenValue2Value['Contentparams']['fontTag'] !== '') {
                delete screenValue2Value['Contentparams']['fontTag'];
              }

              if (screenValue2Value['Contentparams']['contenttag'] === '') {
                screenValue2Value['Contentparams']['contenttag'] = 'Null';
                return screenValue2Value;
              }
              return screenValue2Value;
            } else if (screenValue2Value.Contenttype === 'AudioScript') {
              if (!screenValue2Value.Contentparams.contentdescription) {
                validationErrorArray.push(
                  `<div>${moduleValue2.ModuleName + '-' + slideValue2.SlideName + '-' + screenValue2.ScreenName + ' Audio Script'}</div>`
                );
              }

              audioScriptString = screenValue2Value.Contentparams.contentdescription;

              screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();

              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (screenValue2Value['Contentparams']['contentdescription'] !== oldScreenData[screenValue2Key].Contentparams.contentdescription) {
                    needRenderReset = true;
                  }

                  if (screenValue2Value['Contentparams']['voiceId'] !== oldScreenData[screenValue2Key].Contentparams.voiceId) {
                    needRenderReset = true;
                  }

                  if (screenValue2Value['Contentparams']['voiceRate'] !== oldScreenData[screenValue2Key].Contentparams.voiceRate) {
                    needRenderReset = true;
                  }

                  if (screenValue2Value['Contentparams']['voiceStyle'] !== oldScreenData[screenValue2Key].Contentparams.voiceStyle) {
                    needRenderReset = true;
                  }
                }
              }
              if (screenValue2Value['Contentparams']['contenttag'] === '') {
                screenValue2Value['Contentparams']['contenttag'] = 'Null';
                return screenValue2Value;
              }
              return screenValue2Value;
            } else if (screenValue2Value.Contenttype === 'Background') {
              screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();
              return screenValue2Value;
            } else if (screenValue2Value.Contenttype === 'Image') {
              if (screenValue2Value['Contentparams']['searchstring']) {
                screenValue2Value['Contentparams']['searchstring'] = screenValue2Value['Contentparams']['searchstring'].trim();
              }
              if (screenValue2Value['Contentparams']['contenttag']) {
                screenValue2Value['Contentparams']['contenttag'] = screenValue2Value['Contentparams']['contenttag'].trim();
              }

              if (
                screenValue2Value['Contentparams']['contenttag'] === '' &&
                screenValue2Value['Contentparams']['searchstring'] === '' &&
                screenValue2Value['Contentparams']['contentdescription'] === ''
              ) {
                let imageArray = [];
                imageCountValidate.map((imgData, i) => {
                  if (moduleKey2 + '_' + slideKey2 + '_' + screenKey2 + '_' + screenValue2Key === imgData) {
                    if (imgData) {
                      imageArray = { ...imageCountValidate };
                      imageArray.splice(1, i);
                    }
                  }
                });

                imageCountValidateLocal = imageArray;
                delete screenValue2[screenValue2Key];
              } else {
                imageCountValidateLocal = imageCountValidate;
                screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();
              }
              if (screenValue2Value['Contentparams']['contenttag'] === '' || !screenValue2Value['Contentparams']['contenttag'].length) {
                screenValue2Value['Contentparams']['contenttag'] = '';
                // screenValue2Value['Contentparams']['contenttag'] = 'Null';
              }

              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  const hasSameImage =
                    screenValue2Value['Contentparams']['contentdescription'] === oldScreenData[screenValue2Key].Contentparams.contentdescription;
                  if (
                    (screenValue2Value['Contentparams']['searchstring'] &&
                      screenValue2Value['Contentparams']['searchstring'] !== oldScreenData[screenValue2Key].Contentparams.searchstring &&
                      hasSameImage) ||
                    // (screenValue2Value['Contentparams']['contenttag'] !== 'Null' &&
                    (screenValue2Value['Contentparams']['contenttag'] !== '' &&
                      screenValue2Value['Contentparams']['contenttag'] !== oldScreenData[screenValue2Key].Contentparams.contenttag &&
                      hasSameImage)
                  ) {
                    screenValue2Value['Contentparams']['contentdescription'] = '';
                  }
                }
              }

              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (
                    screenValue2Value['Contentparams']['searchstring'] !== oldScreenData[screenValue2Key].Contentparams.searchstring ||
                    screenValue2Value['Contentparams']['contenttag'] !== oldScreenData[screenValue2Key].Contentparams.contenttag ||
                    screenValue2Value['Contentparams']['contentdescription'] !== oldScreenData[screenValue2Key].Contentparams.contentdescription
                  ) {
                    if (
                      (hasData(screenValue2Value) && noData(oldScreenData[screenValue2Key])) ||
                      (!hasData(screenValue2Value) && !noData(oldScreenData[screenValue2Key]))
                    ) {
                      useDifferentTemplate = true;
                    }
                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                } else if (screenValue2Value['Contentparams']['searchstring'] || screenValue2Value['Contentparams']['contenttag'] !== 'Null') {
                  arrayData.push(1);

                  hasDataChanged = true;
                  useDifferentTemplate = true;
                }
              }

              return screenValue2Value;
            } else if (screenValue2Value.Contenttype === 'Video') {
              if (screenValue2Value['Contentparams']['searchstring']) {
                screenValue2Value['Contentparams']['searchstring'] = screenValue2Value['Contentparams']['searchstring'].trim();
              }
              if (screenValue2Value['Contentparams']['contenttag']) {
                screenValue2Value['Contentparams']['contenttag'] = screenValue2Value['Contentparams']['contenttag'].trim();
              }

              if (
                screenValue2Value['Contentparams']['contenttag'] === '' &&
                screenValue2Value['Contentparams']['searchstring'] === '' &&
                screenValue2Value['Contentparams']['contentdescription'] === ''
              ) {
                let videoArray = [];
                videoCountValidate.map((videoData, i) => {
                  if (moduleKey2 + '_' + slideKey2 + '_' + screenKey2 + '_' + screenValue2Key === videoData) {
                    if (videoData) {
                      videoArray = videoCountValidate;
                      videoArray.splice(1, i);
                    }
                  }
                });
                videoCountValidateLocal = videoArray;

                delete screenValue2[screenValue2Key];
              } else {
                videoCountValidateLocal = videoCountValidate;
                screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();
              }
              if (screenValue2Value['Contentparams']['contenttag'] === '' || !screenValue2Value['Contentparams']['contenttag'].length) {
                screenValue2Value['Contentparams']['contenttag'] = '';
                // screenValue2Value['Contentparams']['contenttag'] = 'Null';
              }

              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  const hasSameVideo =
                    screenValue2Value['Contentparams']['contentdescription'] === oldScreenData[screenValue2Key].Contentparams.contentdescription;
                  if (
                    (screenValue2Value['Contentparams']['searchstring'] &&
                      screenValue2Value['Contentparams']['searchstring'] !== oldScreenData[screenValue2Key].Contentparams.searchstring &&
                      hasSameVideo) ||
                    (screenValue2Value['Contentparams']['contenttag'] !== '' &&
                      // (screenValue2Value['Contentparams']['contenttag'] !== 'Null' &&
                      screenValue2Value['Contentparams']['contenttag'] !== oldScreenData[screenValue2Key].Contentparams.contenttag &&
                      hasSameVideo)
                  ) {
                    screenValue2Value['Contentparams']['contentdescription'] = '';
                  }
                }
              }

              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (
                    screenValue2Value['Contentparams']['searchstring'] !== oldScreenData[screenValue2Key].Contentparams.searchstring ||
                    screenValue2Value['Contentparams']['contenttag'] !== oldScreenData[screenValue2Key].Contentparams.contenttag ||
                    screenValue2Value['Contentparams']['contentdescription'] !== oldScreenData[screenValue2Key].Contentparams.contentdescription
                  ) {
                    if (
                      (hasData(screenValue2Value) && noData(oldScreenData[screenValue2Key])) ||
                      (!hasData(screenValue2Value) && !noData(oldScreenData[screenValue2Key]))
                    ) {
                      useDifferentTemplate = true;
                    }

                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                } else if (screenValue2Value['Contentparams']['searchstring'] || screenValue2Value['Contentparams']['contenttag'] !== 'Null') {
                  arrayData.push(1);

                  hasDataChanged = true;
                  useDifferentTemplate = true;
                }
              }

              return screenValue2Value;
            } else if (screenValue2Value.Contenttype === 'Illustration') {
              if (screenValue2Value['Contentparams']['searchstring']) {
                screenValue2Value['Contentparams']['searchstring'] = screenValue2Value['Contentparams']['searchstring'].trim();
              }
              if (screenValue2Value['Contentparams']['contenttag']) {
                screenValue2Value['Contentparams']['contenttag'] = screenValue2Value['Contentparams']['contenttag'].trim();
              }

              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (
                    screenValue2Value['Contentparams']['searchstring'] !== oldScreenData[screenValue2Key].Contentparams.searchstring ||
                    screenValue2Value['Contentparams']['contenttag'] !== oldScreenData[screenValue2Key].Contentparams.contenttag
                  ) {
                    screenValue2Value['Contentparams']['contentdescription'] = '';
                  }
                }
              }
              if (
                screenValue2Value['Contentparams']['contenttag'] === '' &&
                screenValue2Value['Contentparams']['searchstring'] === '' &&
                screenValue2Value['Contentparams']['contentdescription'] === ''
              ) {
                let illustrationArray = [];

                illustrationCountValidate.map((illuData, i) => {
                  if (moduleKey2 + '_' + slideKey2 + '_' + screenKey2 + '_' + screenValue2Key === illuData) {
                    if (illuData) {
                      illustrationArray = illustrationCountValidate;
                      illustrationArray.splice(1, i);
                    }
                  }
                });
                illustrationCountValidateLocal = illustrationArray;
                delete screenValue2[screenValue2Key];
              } else {
                illustrationCountValidateLocal = illustrationCountValidate;
                screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();
              }

              if (screenValue2Value['Contentparams']['contenttag'] === '' || !screenValue2Value['Contentparams']['contenttag'].length) {
                screenValue2Value['Contentparams']['contenttag'] = 'Null';
              }
              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (
                    screenValue2Value['Contentparams']['searchstring'] !== oldScreenData[screenValue2Key].Contentparams.searchstring ||
                    screenValue2Value['Contentparams']['contenttag'] !== oldScreenData[screenValue2Key].Contentparams.contenttag ||
                    (screenValue2Value['Contentparams']['contentdescription'] &&
                      oldScreenData[screenValue2Key].Contentparams.contentdescription &&
                      screenValue2Value['Contentparams']['contentdescription'] !== oldScreenData[screenValue2Key].Contentparams.contentdescription)
                  ) {
                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                } else if (screenValue2Value['Contentparams']['searchstring'] || screenValue2Value['Contentparams']['contenttag'] !== 'Null') {
                  arrayData.push(1);

                  hasDataChanged = true;
                }
              }
              return screenValue2Value;
            } else if (screenValue2Value.Contenttype === 'TemplateID') {
              if (arrayData.length > 0) {
                if (editProject.clientSettings.manualTemplate) {
                  screenValue2Value['Contentparams']['contentdescription'] = 'no template';
                }
              }
              ostCountValidateArr.map(ostData => {
                let ostValidateData = ostData.slice(0, -5);
                if (moduleKey2 + '_' + slideKey2 + '_' + screenKey2 === ostValidateData) {
                  if (ostData) {
                    if (editProject.clientSettings.manualTemplate) {
                      screenValue2Value['Contentparams']['contentdescription'] = 'no template';
                    }

                    return screenValue2Value;
                  }
                }
              });
              imageCountValidateLocal.map(imgData => {
                let imgValidateData = imgData.slice(0, -5);
                if (moduleKey2 + '_' + slideKey2 + '_' + screenKey2 === imgValidateData) {
                  if (imgData) {
                    if (editProject.clientSettings.manualTemplate) {
                      screenValue2Value['Contentparams']['contentdescription'] = 'no template';
                    }
                    return screenValue2Value;
                  }
                }
              });
              illustrationCountValidateLocal.map(iluData => {
                let illuValidateData = iluData.slice(0, -5);
                if (moduleKey2 + '_' + slideKey2 + '_' + screenKey2 === illuValidateData) {
                  if (iluData) {
                    if (editProject.clientSettings.manualTemplate) {
                      screenValue2Value['Contentparams']['contentdescription'] = 'no template';
                    }
                    return screenValue2Value;
                  }
                }
              });
              videoCountValidateLocal.map(videoData => {
                let videoValidateData = videoData.slice(0, -5);
                if (moduleKey2 + '_' + slideKey2 + '_' + screenKey2 === videoValidateData) {
                  if (videoData) {
                    if (editProject.clientSettings.manualTemplate) {
                      screenValue2Value['Contentparams']['contentdescription'] = 'no template';
                    }
                    return screenValue2Value;
                  }
                }
              });
              screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();

              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (
                    editProject.template?.mediaUrl &&
                    moduleKey2 === editProject.current.moduleName &&
                    slideKey2 === editProject.current.slideName &&
                    screenKey2 === editProject.current.screenName &&
                    editProject.template?.mediaUrl !== oldScreenData[screenValue2Key].Contentparams.contentdescription
                  ) {
                    arrayData.push(1);
                    hasDataChanged = true;
                  }
                }
              }

              if (screenValue2Value['Contentparams']['useTemplateStatus'] !== '') {
                delete screenValue2Value['Contentparams']['useTemplateStatus'];
                return screenValue2Value;
              }

              return screenValue2Value;
            } else if (screenValue2Value.Contenttype === 'ColourScheme') {
              screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();
              if (screenValue2Value['Contentparams']['contentdescription'].length === 8) {
                screenValue2Value['Contentparams']['contentdescription'] = screenValue2Value['Contentparams']['contentdescription'].split('*')[0];
              }
              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (screenValue2Value['Contentparams']['contentdescription'] !== oldScreenData[screenValue2Key].Contentparams.contentdescription) {
                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                }
              }
              return screenValue2Value;
            } else if (screenValue2Value.Contenttype === 'ScreenDuration') {
              screenValue2Value['Contentparams']['sequenceid'] = countPlusOne();
              if (screenValue2Value['Contentparams']['contenttag'] === '') {
                screenValue2Value['Contentparams']['contenttag'] = 'Null';
              }
              if (screenValue2Value['Contentparams']['contentdescription'] === '') {
                screenValue2Value['Contentparams']['contentdescription'] = '7';
              }
              if (oldScreenData) {
                if (oldScreenData[screenValue2Key]) {
                  if (screenValue2Value['Contentparams']['contentdescription'] !== oldScreenData[screenValue2Key].Contentparams.contentdescription) {
                    arrayData.push(1);

                    hasDataChanged = true;
                  }
                }
              }
              return screenValue2Value;
            }
            return screenValue2Value;
          });

          if (oldScreenData) {
            let oldScreenKeys = Object.keys(oldScreenData);
            let newScreenKeys = Object.keys(moduleValue2.slides[slideKey2].Screens[screenKey2]);
            oldScreenKeys = oldScreenKeys.filter(key => key !== 'mediaurl');
            newScreenKeys = newScreenKeys.filter(key => key !== 'mediaurl');
            if (oldScreenKeys.toString() !== newScreenKeys.toString()) {
              arrayData.push(1);
              hasDataChanged = true;

              if (
                moduleKey2 === editProject.current.moduleName &&
                slideKey2 === editProject.current.slideName &&
                screenKey2 === editProject.current.screenName
              ) {
                useDifferentTemplate = true;
              }
            }
          }

          if (arrayData.length) {
            if (screenValue2['mediaurl']) {
              delete projectModules[moduleKey2].slides[slideKey2].Screens[screenKey2]['mediaurl'];
            }
            screenChanges.push({ moduleKey2, slideKey2, screenKey2 });
            slideChanges.push(moduleKey2 + '_' + slideKey2);
          } else if (needRenderReset) {
            slideChanges.push(moduleKey2 + '_' + slideKey2);
          }

          if (!OSTFound) {
            validationErrorArray.push(`<div>${'Atleast one Header / Paragraph / List'}</div>`);
          }

          // const compareAudioScript = calculatePatternMatchPercentage(audioScriptString, ostString);

          // if (compareAudioScript < 80) {
          //   validationErrorArray.push(
          //     `<div>${
          //       moduleValue2.ModuleName +
          //       '-' +
          //       slideValue2.SlideName +
          //       '-' +
          //       screenValue2.ScreenName +
          //       '-' +
          //       'audio script did not match with ost. At least 80% audio script should match with ost'
          //     }</div>`
          //   );
          // }
        });

        if (cachedProjectModule?.slides?.[slideKey2]?.SlideAudioVO !== moduleValue2?.slides?.[slideKey2]?.SlideAudioVO) {
          slideChanges.push(moduleKey2 + '_' + slideKey2);
        }

        if (cachedProjectModule?.slides?.[slideKey2]?.Screens && slideValue2?.Screens) {
          if (Object.entries(slideValue2?.Screens)?.length !== Object.entries(cachedProjectModule?.slides?.[slideKey2]?.Screens)?.length) {
            slideChanges.push(moduleKey2 + '_' + slideKey2);
          }
        }
      });
    });

    if (validationErrorArray.length) {
      return showToast(validationErrorArray);
    }

    const generatePreviewData = hasDataChanged || editProject.template?.mediaUrl || useDifferentTemplate;

    if (generatePreviewData) {
      if (!editProject.clientSettings.manualTemplate) {
        updatedProject = await templateForLastActiveScreen(updatedProject, useDifferentTemplate, editProject);
      }
    }

    const projectModulesData = Object.entries(updatedProject.ProjectData[editProject.project.ProjectID].Modules);
    for (const [moduleKey, moduleValue] of projectModulesData) {
      const slidesArray = Object.entries(moduleValue.slides);
      for (const [slideKey, slideValue] of slidesArray) {
        const screenArray = Object.entries(slideValue.Screens);
        for (const [screenKey, screenValue] of screenArray) {
          if (!screenValue['TM01'].Contentparams.contentdescription || screenValue['TM01'].Contentparams.contentdescription === 'no template') {
            updatedProject = await templateForLastActiveScreen(updatedProject, true, editProject, {
              moduleId: moduleKey,
              slideId: slideKey,
              screenId: screenKey,
            });
          }
        }
      }
    }

    let renderSlides = {};
    if (editProject.cacheProject.renderSlides) {
      renderSlides = { ...editProject.cacheProject.renderSlides };
    }

    // if (generatePreviewData) {
    renderSlides = {
      ...renderSlides,
      [`${editProject.current.moduleName}-${editProject.current.slideName}`]: false,
    };
    // }

    const projectDataPayload = {
      dbdata: {
        [editProject.project.ProjectID]: updatedProject.ProjectData[editProject.project.ProjectID],
      },
      renderSlides,
    };
    await saveProjectData(projectDataPayload, generatePreviewData, screenChanges, editProject);
  } catch (error) {
    console.error({ updateScreensOnProject: error });
  }
};
/* Removing segment */
export const removeScreen = ({ project, current, item }) => {
  try {
    let cloneProject = JSON.parse(JSON.stringify(project));
    delete cloneProject.ProjectData[project.ProjectID].Modules[current.moduleName].slides[current.slideName].Screens[item.id];
    return cloneProject;
  } catch (error) {
    console.error(error);
  }
};

export const getSlideId = slides => {
  const lastSlide = slides[slides.length - 1].slideId;

  const slideNumber = lastSlide.replace('SL0', '');
  const slideNumberSplit = slideNumber.split('SL')[1];

  let data;
  if (slideNumberSplit) data = parseInt(slideNumberSplit) + 1;
  else data = parseInt(slideNumber) + 1;

  const newSlideId = data < 10 ? 'SL0' + data : 'SL' + data;
  const newSlideNumber = newSlideId.replace('SL', '');

  return { data, newSlideId, newSlideNumber };
};

export const getNewModuleId = projectModules => {
  const moduleIds = projectModules.map(projectModule => projectModule[0]);
  const sortedProjectModuleIds = moduleIds.sort();
  const lastModuleId = sortedProjectModuleIds[sortedProjectModuleIds.length - 1];

  const lastModuleNumber = lastModuleId.replace('M0', '');
  const lastModuleNumberSplit = lastModuleNumber.split('M')[1];

  let data;
  if (lastModuleNumberSplit) data = parseInt(lastModuleNumberSplit) + 1;
  else data = parseInt(lastModuleNumber) + 1;

  const newModuleId = data < 10 ? 'M0' + data : 'M' + data;

  return { newModuleId, data };
};

const showToast = message => {
  Swal.fire({
    html: message,
    icon: 'warning',
    focusConfirm: false,
    confirmButtonText: 'OK',
    confirmButtonColor: '#d33',
  });
};

let COUNT = 0;
const countPlusOne = () => {
  COUNT += 1;
  return COUNT.toString();
};

const hasData = screenData => {
  let data = false;
  if (screenData['Contentparams']['searchstring'] && screenData['Contentparams']['searchstring'] !== 'Null') {
    data = true;
  }
  if (screenData['Contentparams']['contenttag'] && screenData['Contentparams']['contenttag'] !== 'Null') {
    data = true;
  }

  if (screenData['Contentparams']['contentdescription'] && screenData['Contentparams']['contentdescription'] !== 'Null') {
    data = true;
  }

  return data;
};

const noData = screenData => {
  let noData = true;
  if (screenData['Contentparams']['searchstring'] && screenData['Contentparams']['searchstring'] !== 'Null') {
    noData = false;
  }
  if (screenData['Contentparams']['contenttag'] && screenData['Contentparams']['contenttag'] !== 'Null') {
    noData = false;
  }

  if (screenData['Contentparams']['contentdescription'] && screenData['Contentparams']['contentdescription'] !== 'Null') {
    noData = false;
  }

  return noData;
};

const templateForLastActiveScreen = async (projectDataforAPI, useDifferentTemplate, editProject, { moduleId, slideId, screenId } = {}) => {
  let choosenTemplate = '';

  const moduleKey = moduleId || editProject.current.moduleName;
  const slideKey = slideId || editProject.current.slideName;
  const screenKey = screenId || editProject.current.screenName;

  if (!editProject.template?.mediaUrl) {
    if (!useDifferentTemplate) {
      return projectDataforAPI;
    } else {
      const projectName = projectDataforAPI.ProjectData[editProject.project.projectID]?.ProjectName;
      const activeScreen =
        projectDataforAPI?.ProjectData?.[editProject.project.ProjectID]?.['Modules']?.[moduleKey]?.['slides']?.[slideKey]?.['Screens']?.[screenKey];

      const modifiedScreenData = activeScreen && JSON.parse(JSON.stringify(activeScreen));
      for (const key in modifiedScreenData) {
        // removes fontTag from OS01 object and appends the value to contentdescription
        if (modifiedScreenData[key].Contenttype === 'OST') {
          const { fontTag, ...restParams } = modifiedScreenData[key].Contentparams;
          modifiedScreenData[key].Contentparams = {
            ...restParams,
            contentdescription: restParams.contentdescription + fontTag,
          };
        }
        if (['Image', 'Video', 'Illustration'].includes(modifiedScreenData[key].Contenttype)) {
          const { searchstring, contenttag, contentdescription } = modifiedScreenData[key].Contentparams;
          if (!searchstring && !contenttag && !contentdescription) {
            delete modifiedScreenData[key];
          }
        }
      }

      const activeScreenDetails = {
        ScreenName:
          projectDataforAPI.ProjectData[editProject.project.ProjectID]?.['Modules']?.[moduleKey]?.['slides']?.[slideKey]?.['Screens']?.[screenKey]?.[
            'ScreenName'
          ],
        ...activeScreen,
      };

      const activeSlideData = projectDataforAPI.ProjectData[editProject.project.ProjectID]?.['Modules'][moduleKey]?.['slides']?.[slideKey];

      const SlideAudioVO = activeSlideData?.['SlideAudioVO'] ? activeSlideData?.['SlideAudioVO'] : 'Null';

      const screenContent = {
        [editProject.project.ProjectID]: {
          ProjectName: projectName,
          Modules: {
            [moduleKey]: {
              ModuleName: projectDataforAPI.ProjectData[editProject.project.ProjectID]?.['Modules']?.[moduleKey]?.['ModuleName'],
              slides: {
                [slideKey]: {
                  SlideName:
                    projectDataforAPI.ProjectData[editProject.project.ProjectID]?.['Modules']?.[moduleKey]?.['slides'][slideKey]?.['SlideName'],
                  SlideAudioVO: SlideAudioVO,
                  Screens: {
                    [screenKey]: activeScreenDetails,
                  },
                },
              },
            },
          },
        },
      };

      // setIsTemplateLoading(true);
      const templateResponse = await getTemplateData(screenContent, editProject.project.ProjectID);
      // setIsTemplateLoading(false);

      let templateData = [];
      if (templateResponse?.[0]?.length) {
        templateData = templateResponse[0];

        const hasPreviousTemplate = templateResponse[0].find(
          temp => temp.mediaUrl === activeSlideData?.['Screens']?.[screenKey]?.['TM01']?.['Contentparams']?.['contentdescription']
        );

        if (hasPreviousTemplate) {
          choosenTemplate = hasPreviousTemplate.mediaUrl;
        } else {
          const randomNumber = getRandomNumber({ max: templateData.length - 1 });
          choosenTemplate = templateData[randomNumber].mediaUrl;
        }
      }

      // setRecommendedTemplates(templateData);
    }
  } else {
    choosenTemplate = editProject.template?.mediaUrl;
  }

  if (choosenTemplate) {
    projectDataforAPI.ProjectData[editProject.project.ProjectID]['Modules'][moduleKey]['slides'][slideKey]['Screens'][screenKey]['TM01'][
      'Contentparams'
    ]['contentdescription'] = choosenTemplate;
  } else {
    projectDataforAPI.ProjectData[editProject.project.ProjectID]['Modules'][moduleKey]['slides'][slideKey]['Screens'][screenKey]['TM01'][
      'Contentparams'
    ]['contentdescription'] = 'no template';
  }

  return projectDataforAPI;
};

export const saveProjectData = (projectDataPayload, generatePreviewData, screenChanges, editProject) => {
  return updateProject(projectDataPayload)
    .then(async response => {
      try {
        const projectData = JSON.parse(response.ProjectData);

        store.dispatch(EditProjectActions.setProject({ ...response, ProjectData: JSON.parse(response.ProjectData) }));
        store.dispatch(EditProjectActions.setCacheProject({ ...response, ProjectData: JSON.parse(response.ProjectData) }));

        const { moduleName, slideName, screenName } = editProject?.current;
        const activeScreenValues = getScreen(JSON.parse(response.ProjectData), {
          moduleName,
          slideName,
          screenName,
        });
        // const activeScreenValues = getScreen(JSON.parse(response.ProjectData), {
        //   ActiveModule: moduleName,
        //   ActiveSlide: slideName,
        //   ActiveScreen: screenName,
        // });

        // Assuming firt object of OST and ColorScheme consider as global
        // const ost = getDataFromScreen({ screen: activeScreenValues, name: 'OST' })[0];
        // const colourScheme = getDataFromScreen({ screen: activeScreenValues, name: 'ColourScheme' })[0];
        store.dispatch(EditProjectActions.setScreen(activeScreenValues));
        // Updating on Project
        let updateProject = updateScreenOnProject({ project: editProject.project, screen: activeScreenValues, current: editProject.current });
        store.dispatch(EditProjectActions.setProject(updateProject));
        /* I have  comment below code because if any body change to segement 2 or greater, 
          It will render at first segement
         */
        // store.dispatch(
        //   EditProjectActions.setCurrent({
        //     moduleName,
        //     slideName,
        //     screenName,
        //     ...ost.Contentparams,
        //     bgColor: colourScheme.Contentparams.contentdescription,
        //     fontEntireVideo: false,
        //     voiceEntireVideo: false,
        //   })
        // );

        if (generatePreviewData) {
          store.dispatch(EditProjectActions.setPreview('generatePreview'));
          for (const screen of screenChanges) {
            await generatePreviewWithProjectId(
              editProject.project.ProjectName,
              projectData,
              editProject.project.ProjectID,
              {
                moduleId: screen.moduleKey2,
                slideId: screen.slideKey2,
                screenId: screen.screenKey2,
              },
              true
            );
          }
        }

        let message = 'Project updated successfully';
        let meregedScreen = { ...activeScreenValues };
        if (generatePreviewData) {
          message = 'Project updated and preview is generating';

          let cloneScreen = JSON.parse(JSON.stringify(editProject.screen));

          if (cloneScreen && cloneScreen.mediaurl && cloneScreen.mediaurl.PreviewUrl) {
            meregedScreen = { ...meregedScreen, mediaurl: { PreviewUrl: 'generatePreview', ThumbnailUrl: '' } };
          }

          store.dispatch(EditProjectActions.setScreen(meregedScreen));
          // Updating on Project
          let updateProject = updateScreenOnProject({ project: editProject.project, screen: meregedScreen, current: editProject.current });
          store.dispatch(EditProjectActions.setProject(updateProject));
        }
        showToaster(message, 'success');
      } catch (error) {
        console.error({ saveProjectDataError: error });
        showToaster('Something went wrong. Please Try Again', 'warning');
      }
    })
    .catch(error => {
      const err = error.response?.data?.error;

      console.error({ err: error });

      showToaster(err || 'Something went wrong. Please Try Again', 'warning', 10000);
    });
};

const generatePreviewWithProjectId = async (ProjectName, projectData, projectId, { moduleId, slideId, screenId } = {}, forceGenerate = false) => {
  try {
    if (
      !(
        projectData[projectId]['Modules'][moduleId]['slides'][slideId]['Screens'][screenId]['TM01']['Contentparams']['contentdescription'] ===
          'Null' ||
        projectData[projectId]['Modules'][moduleId]['slides'][slideId]['Screens'][screenId]['TM01']['Contentparams']['contentdescription'] === '' ||
        projectData[projectId]['Modules'][moduleId]['slides'][slideId]['Screens'][screenId]['TM01']['Contentparams']['contentdescription'] ===
          'no template'
      )
    ) {
      const SlideAudioVO = projectData[projectId]?.['Modules'][moduleId]?.['slides']?.[slideId]?.['SlideAudioVO']
        ? projectData[projectId]?.['Modules'][moduleId]?.['slides'][slideId]?.['SlideAudioVO']
        : 'Null';

      let previewContent = {
        [projectId]: {
          ProjectName: ProjectName,
          Modules: {
            [moduleId]: {
              ModuleName: projectData[projectId]?.['Modules']?.[moduleId]?.['ModuleName'],
              slides: {
                [slideId]: {
                  SlideName: projectData[projectId]?.['Modules']?.[moduleId]?.['slides'][slideId]?.['SlideName'],
                  SlideAudioVO: SlideAudioVO,
                  Screens: {
                    [screenId]: {
                      ScreenName: projectData[projectId]?.['Modules']?.[moduleId]?.['slides']?.[slideId]?.['Screens']?.[screenId]?.['ScreenName'],
                      ...projectData?.[projectId]?.['Modules']?.[moduleId]?.['slides']?.[slideId]?.['Screens']?.[screenId],
                    },
                  },
                },
              },
            },
          },
        },
      };
      if (previewContent[projectId].Modules[moduleId].slides[slideId].Screens[screenId]['AS01'].Contentparams.audioFile) {
        delete previewContent[projectId].Modules[moduleId].slides[slideId].Screens[screenId]['AS01'].Contentparams.audioFile;
      }
      await generateProjectPreview(previewContent, forceGenerate);
    }
  } catch (error) {
    console.error(error);
  }
};

export const compareChangeInProject = (cacheproject, project) => {
  console.log(cacheproject, 'cacheProject');
  console.log(project, 'project');

  let hasChange = false;
  return hasChange;
};

export const removeMediaUrlFilter = (projectData, projectId) => {
  return new Promise((resolve, reject) => {
    Object.entries(projectData[projectId].Modules).map((moduleData, moduleIndex) => {
      let mData = moduleData[1];
      let slides = mData.slides;
      Object.entries(slides).map((slideData, moduleIndex) => {
        let slide = slideData[1];
        let screens = slide.Screens;
        Object.entries(screens).map((screenData, moduleIndex) => {
          delete projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]['AS01'].Contentparams.audioFile;
          delete projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]['AS01'].Contentparams.voiceStyle;
          delete projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]['AS01'].Contentparams.voiceId;
          delete projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]['AS01'].Contentparams.voiceRate;
          delete projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]['mediaurl'];

          delete projectData[projectId].Modules[moduleData[0]].slides[slideData[0]].draft;
        });
      });
    });
    resolve(projectData);
  });
};

export const checkPreviewVideoLength = async activeSlide => {
  let oneSecondScreen = false;

  if (activeSlide) {
    for (let screenValue of Object.values(activeSlide.Screens)) {
      if (screenValue.mediaurl?.PreviewUrl) {
        try {
          /* VideoDuration has the script in index.html */
          // eslint-disable-next-line no-undef
          const videoDuration = new VideoDuration();
          const duration = await videoDuration.analyze(screenValue.mediaurl.PreviewUrl);
          if (duration?.seconds === 1) {
            oneSecondScreen = screenValue.ScreenName;

            break;
          }
        } catch (error) {}
      }
    }
  }

  return oneSecondScreen;
};
