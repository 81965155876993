import { RandomGenerator } from '../services/helper';
import { PDFDocument } from 'pdf-lib';
export const checkInvalidChars = (OSTTextValidation, value) => {
  let totalChars = value.length;
  let i = 0;
  let invaliChars = [];
  while (i < totalChars) {
    if (!OSTTextValidation(value.charAt(i))) {
      if (!invaliChars.find(item => item === value.charAt(i))) {
        invaliChars.push(value.charAt(i));
      }
    }
    i++;
  }
  let chars = invaliChars.length === 1 ? invaliChars[0] : invaliChars.join(' ');

  var invalidMessage = `Invalid character${chars?.length > 1 ? 's' : ''} found  ${chars}`;
  return invalidMessage;
};
export const readS3File = async ({ url }) => {
  const res = await fetch(url);
  const blobdata = await res.arrayBuffer();
  const fd = new FormData();
  const file = new File([blobdata], 'my.pdf', { type: 'application/pdf' });
  fd.append('image', file);
  return URL.createObjectURL(file);
};

export const getVideoSource = ({ projectData, project, activeModule = null, activeSlide = null }) => {
  try {
    let obj = Object.keys(projectData);
    let key = obj[0];

    let module = activeModule ? activeModule : project.ActiveModule;
    let slide = activeModule ? activeSlide : project.ActiveSlide;
    let pdfSource = projectData[key]?.Modules ? projectData[key]?.Modules[module]?.slides[slide]?.pdfRequestId : '';
    // let pdfSource = projectResponse?.draft?.ocr_segment ? projectResponse?.draft?.ocr_segment : '';
    let str = '';
    if (pdfSource) {
      str = 'uploadDocument';
    }

    return str;
  } catch (error) {
    console.error(error);
  }
};
export const getRenderedSegments = (data, requestId, project) => {
  try {
    let documentIDs = [];
    let ocrdata;
    if (data[requestId].pdfPages) {
      ocrdata = data[requestId]?.pdfPages;
      // documentIDs = ocrdata.map(item => !!item?.hasPreviewGenerated);
      const projectModules = project?.ProjectData ? JSON.parse(project.ProjectData)?.[project.ProjectID]?.Modules : null;
      documentIDs = ocrdata.map(item => {
        if (projectModules) {
          if (!item.hasPreviewGenerated) return !!item?.hasPreviewGenerated;

          let regeneratePdf = true;
          for (const moduleKey in projectModules) {
            let dataFound = false;
            const projectSlides = projectModules[moduleKey].slides;
            for (const slideKey in projectSlides) {
              if (item.image_id === projectSlides[slideKey].pdfFileId) {
                dataFound = true;
                regeneratePdf = false;
                if (project.allRenderUrls?.[moduleKey]?.[slideKey]?.url) {
                  regeneratePdf = true;
                }
              }
            }

            if (dataFound) break;
          }

          if (regeneratePdf) return false;

          return !!item?.hasPreviewGenerated;
        } else {
          return !!item?.hasPreviewGenerated;
        }
      });
    }

    // data[requestId]?.pdfPages.map(item => {
    //   if (item?.hasPreviewGenerated) {
    //     documentIDs.push(!!item.id);
    //   }
    // });

    return documentIDs;
  } catch (error) {
    console.log(error);
  }
};

export const combineContent = data => {
  try {
    let alldata = data.map(item => {
      let itemClone = { ...item };
      let pageDetails = [];
      let paragraph = {};
      itemClone.page_details.map((obj, i) => {
        if (obj.type === 'text') {
          //If last text found
          if (i === itemClone.page_details.length - 1) {
            if (Object.keys(paragraph).length > 0) {
              paragraph.content += '\n \n' + obj.content;
              pageDetails.push(paragraph);
              paragraph = {};
            } else {
              pageDetails.push(obj);
            }
          } else if (Object.keys(paragraph).length === 0) {
            // Add Object
            // if first text found
            paragraph = { ...obj };
          } else {
            paragraph.content += '\n \n' + obj.content;
          }
        } else {
          if (Object.keys(paragraph).length > 0) {
            pageDetails.push(paragraph);
            paragraph = {};
          }
          pageDetails.push(obj);
        }
      });
      itemClone.page_details = pageDetails;
      return itemClone;
    });
    return alldata;
  } catch (error) {
    console.log(error);
  }
};

const fileNames = segmentData => {
  let fileNameCollection = [];
  Object.entries(segmentData.draftData).map(draftData => {
    Object.entries(draftData[1]).map(segment => {
      fileNameCollection.push(segment[1].fileId);
    });
  });
  return fileNameCollection;
};

export const compileData = (pdf, ocrSegmentData) => {
  try {
    let fileNameList = fileNames(ocrSegmentData);
    let combineContentObj = combineContent(pdf);
    let obj = combineContentObj.map(item => {
      return {
        ...item,
        id: RandomGenerator(20),
        isProcessedVideo: fileNameList.filter(image_id => image_id === item.image_id).length > 0,
        page_details: item.page_details.map(page => {
          return { ...page, id: RandomGenerator(20) };
        }),
      };
    });

    return obj;
  } catch (error) {
    console.error(error);
  }
};

export const compile = pdf => {
  let combineContentObj = combineContent(pdf);
  let obj = combineContentObj.map(item => {
    return {
      ...item,
      id: RandomGenerator(20),
      page_details: item.page_details.map(page => {
        return { ...page, id: RandomGenerator(20) };
      }),
    };
  });

  return obj;
};
export const compileOcrData = ({ ocr, ocr_segment, project }) => {
  try {
    // const segmentRenderStatus = getRenderedSegments(JSON.parse(ocr.data), project);
    // console.log(segmentRenderStatus);
    // let ocrData = JSON.parse(ocr.data);
    // const ocrSegmentData = JSON.parse(ocr_segment.data);
    // let pdfId = Object.keys(ocrData)[0];
    // let compileDraftData = compileData(ocrData[pdfId].pdfPages, ocrSegmentData);
    // compileDraftData = compileDraftData.map((item, index) => {
    //   let obj = { ...item };
    //   obj.isRendered = segmentRenderStatus[index];
    //   return obj;
    // });
    // return compileDraftData;
  } catch (error) {
    console.log(error);
  }
};
export const findSelectedSegment = (data, image_id) => {
  try {
    let activeIndex;
    let activeSegmentIndex;
    let activeSegment;
    let activeModule;
    let draftData = data?.draftData ? data.draftData : data?.activeOcrSegments;
    Object.entries(draftData).filter((module, index) => {
      let aa = Object.entries(module[1]).map((segment, i) => {
        let segmentObj = segment[1].fileId === image_id;
        if (segmentObj) {
          activeSegmentIndex = i;
          activeSegment = segment[0];
          activeModule = module[0];
          activeIndex = index;
        }
        return segmentObj;
      });
      return aa;
    });
    return { activePDF: activeModule, activeFile: activeSegment };
  } catch (error) {
    console.error(error);
  }
};
export const renderNotProceedSegmentsOnly = ({ saveDraft, pdfUpload }) => {
  try {
    let list = [];
    pdfUpload.list.map(item => {
      if (item?.isProcessedVideo === true) {
        list.push(item.image_id);
      }
    });
    let copySaveDraft = JSON.parse(JSON.stringify(saveDraft));
    let segments = copySaveDraft.segments;
    segments.activeOcrSegments = segments.activeOcrSegments.filter(item => list.includes(item.fileId) === false);
    return segments;
  } catch (error) {
    console.log(error);
  }
};

const readFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);

    reader.readAsArrayBuffer(file);
  });
};

export const getPDFPageCount = async file => {
  const arrayBuffer = await readFile(file);

  const pdf = await PDFDocument.load(arrayBuffer);

  return pdf.getPageCount();
};

export const updateSequenceIds = (obj, currentId = 1) => {
  // Check if the object is valid and an actual object
  if (obj && typeof obj === 'object') {
    // Loop through the keys of the object
    for (const key in obj) {
      // Check if the property is an object itself
      if (typeof obj[key] === 'object') {
        // Recursively call the function for nested objects
        currentId = updateSequenceIds(obj[key], currentId);
      }

      // If the property is Contentparams, update the sequenceid
      if (obj[key] && obj[key].Contentparams) {
        obj[key].Contentparams.sequenceid = currentId.toString();
        currentId++;
      }
    }
  }
  return currentId;
};
